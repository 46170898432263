import React, { useEffect, useState } from 'react';
import Permission from '../../../permission/Permission';
import MenuList from '../../../gridComponents/MenuList';
import { Grid, Backdrop, Fade, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  BASIC_PRODUCT_DESTRIBUTION_CENTER,
  BASIC_PRODUCT_AGE_RENGE,
  BASIC_PRODUCT_MATERIAL_LIST,
  BASIC_IMAGE_UPLOAD,
  BASIC_PRODUCT_CREATE_API,
  DELETE_IMAGE_LINK,
  PRODUCT_FIELD_CONFIGURATION
} from '../../../configurations/configApi';
import {
  apiPost,
  fetchUrl,
  apiDelete
} from '../../../apiActions/action';
import { handleError } from 'views/FunctionalComponents/ErrorHandle';
import LoadingOverlay from '../../../components/overlays/LoadingOverlay';
import MessageComponent from '../../FunctionalComponents/messageComponent';
import { Link } from 'react-router-dom';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';
import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const allowConsolidation = [
  { id: 1, value: 'Allowed', name: 'Allowed' },
  { id: 2, value: 'NotAllowed', name: 'NotAllowed' }
];

const CreateBasicProduct = () => {
  const classes = useStyles();

  const [product, setProduct] = useState('');
  const [isSelctedTab, setIsSelectedTab] = useState(true);
  const [destributionCenter, setDistributionCenter] = useState([]);
  const [configuration, setConfiguration] = useState({});
  const [
    nonActiveConfigurationfields,
    setNonActiveConfigurationfields
  ] = useState([]);
  const [sku, setSku] = useState('');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [brand, setBrand] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [asin, setAsin] = useState('');
  const [isbn, setIsbn] = useState('');
  const [mpn, setMpn] = useState('');
  const [harmonizedCode, setHarmonizedCode] = useState('');
  const [
    sortDescriptionForBasic,
    setSortDescriptionForBasic
  ] = useState('');
  const [height, setHeight] = useState('');
  const [widths, setWidth] = useState('');
  const [weight, setWeight] = useState('');
  const [supplier, setSupplier] = useState('');
  const [supplierPO, setSupplierPO] = useState('');
  const [warrenty, setWarrenty] = useState('');
  const [texCode, setTexCode] = useState('');
  const [
    allowConsolidationForBasic,
    setAllowConsolidationForBasic
  ] = useState('');
  const [defaultPackage, setDefaultPackage] = useState('');
  const [multipackQuantity, setMultipackQuantity] = useState('');
  const [warehouseLocation, setWarehouseLocation] = useState('');
  const [receivedInInventory, setReceivedInInventory] = useState('');
  const [storeTitleForBasic, setStoreTitleForBasic] = useState('');
  const [displayInStore, setDisplayInStore] = useState('');
  const [storeCategoryForBasic, setStoreCategoryForBasic] = useState(
    ''
  );
  const [
    storeDescriptionForBasic,
    setStoreDescriptionForBasic
  ] = useState('');
  const [storeMetaDescription, setStoreMetaDescription] = useState(
    ''
  );
  const [condition, setCondition] = useState('New');
  const [retailPrice, setRetailPrice] = useState(0);
  const [startingPrice, setStartingPrice] = useState(0);
  const [reservePrice, setReservePrice] = useState(0);
  const [storePrice, setStorePrice] = useState(0);
  const [sellerCost, setSellerCost] = useState(0);
  const [productMargin, setProductMargin] = useState(0);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [estimatedShippingCost, setEstimatedShippingCost] = useState(
    0
  );
  const [ageRange, setAgeRange] = useState('');
  const [amzRepricerAutoPrice, setAmzRepricerAutoPrice] = useState(
    ''
  );
  const [allAgeRenge, setAllAgeRenge] = useState([]);
  // const [brandCASuggestion, setBrandCASuggestion] = useState("");
  const [care, setCare] = useState('');
  const [color, setColor] = useState('');
  // const [colorCASuggestion , setColorCASuggestion] = useState("");
  const [
    distributionDesignationAMZC,
    setDistributionDesignationAMZC
  ] = useState('');
  // const [eanAMZCASuggestion, setEanAMZCASuggestion] = useState("");
  const [ebayCatogoryId, setEbayCatogoryId] = useState('');
  const [euSize, setEUSize] = useState(0);
  const [fightClubPrice, setFightClubPrice] = useState(0);
  const [flightClubCost, setFlightClubCost] = useState(0);
  const [
    flightClubQuantityBuffered,
    setFlightClubQuantityBuffered
  ] = useState('');
  const [
    footwearSizeSizeAMZCASu,
    setFootwearSizeSizeAMZCASu
  ] = useState('');
  const [
    footwearSizeSizeSystemA,
    setFootwearSizeSizeSystemA
  ] = useState('');
  const [
    footwearSizeWidthAMZCAS,
    setFootwearSizeWidthAMZCAS
  ] = useState('');
  const [gender, setGender] = useState('Unisex');
  const [goatSku, setGoatSku] = useState('');
  const [itemPackageQuantity, setItemPackageQuantity] = useState('');
  const [origin, setOrigin] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [productWeight, setProductWeight] = useState('');
  const [releaseYear, setReleaseYear] = useState(new Date().getFullYear());
  const [shopifyPosition, setShopifyPosition] = useState('');
  const [shopifyProductType, setShopifyProductType] = useState('Clothing');
  const [shopifyProductURL, setShopifyProductURL] = useState('');
  const [size, setSize] = useState('');
  // const [sizeCASuggestion, setSizeCASuggestion] = useState("");
  const [ukSize, setUkSize] = useState('');
  const [walmartBulletPoints, setWalmartBulletPoints] = useState('');
  const [walmartShoeCategory, setWalmartShoeCategory] = useState('');
  const [allMaterailName, setAllMaterailName] = useState('');
  const [materailValue, setMaterailValue] = useState('Febric');
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [imageName, setImageName] = useState('');
  const [imageLink, setImageLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ITEMIMAGEURL1, setITEMIMAGEURL1] = useState('');
  const [ITEMIMAGEURL2, setITEMIMAGEURL2] = useState('');
  const [ITEMIMAGEURL3, setITEMIMAGEURL3] = useState('');
  const [ITEMIMAGEURL4, setITEMIMAGEURL4] = useState('');
  const [ITEMIMAGEURL5, setITEMIMAGEURL5] = useState('');
  const [ITEMIMAGEURL6, setITEMIMAGEURL6] = useState('');
  const [ITEMIMAGEURL7, setITEMIMAGEURL7] = useState('');
  const [ITEMIMAGEURL8, setITEMIMAGEURL8] = useState('');
  const [imageId, setImageId] = useState([]);
  const [category, setCategory] = useState('');
  // const [editDistributionQuantity, setEditDistributionQuantity] = useState(false);

  const handleChangeForProduct = name => {
    if (name === 'product') {
      setIsSelectedTab(true);
    } else {
      setIsSelectedTab(false);
    }
  };

  const fetchProductFieldConfiguration = () => {
    fetchUrl(PRODUCT_FIELD_CONFIGURATION, getVal => {
      if (getVal.status) {
        // console.log(getVal.response.data);
        const {
          field_configuration,
          attribute_configuration
        } = getVal.response.data;

        const configurationfields = [
          ...field_configuration,
          ...attribute_configuration
        ];

        const nonActiveConfigurationfieldList = configurationfields.filter(
          each => !each.is_active
        );

        setNonActiveConfigurationfields(
          nonActiveConfigurationfieldList.map(each => each.name)
        );

        const configurationObj = configurationfields.reduce(
          (each, obj) => ({
            ...each,
            [obj.name]: obj.is_active
          }),
          {}
        );

        // console.log(configurationObj);
        setConfiguration(configurationObj);
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  useEffect(() => {
    fetchProductFieldConfiguration();
    fetchUrl(`${BASIC_PRODUCT_DESTRIBUTION_CENTER}`, getVal => {
      if (getVal.status) {
        setDistributionCenter(getVal.response.data.results);
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  }, []);

  const hanldeChangeSku = e => {
    setSku(e.target.value);
    handleChangeMpn(e);
    document.getElementById('skuForBasic').value = 'hi';
    if (e.target.value) {
      document.getElementById('skuForBasic').style.borderColor =
        'blue';
    }
  };

  const handleChangeTitle = e => {
    setTitle(e.target.value);
    if (e.target.value) {
      document.getElementById('titleForBasic').style.borderColor =
        'blue';
    }
    if (sku.length === 0) {
      document.getElementById('skuForBasic').style.borderColor =
        '#D10000';
    }
    if (mpn.length === 0) {
      document.getElementById('mpnForBasic').style.borderColor =
        '#D10000';
    }
  };

  const handleChangeSubTitle = e => {
    setSubTitle(e.target.value);
  };

  const hanldeChangeBrand = e => {
    setBrand(e.target.value);
    setManufacturer(e.target.value);
  };

  const hanldeChangeAsin = e => {
    setAsin(e.target.value);
  };

  const hanldeChangeIsbn = e => {
    setIsbn(e.target.value);
  };

  const handleChangeMpn = e => {
    setMpn(e.target.value);
    if (e.target.value) {
      document.getElementById('mpnForBasic').style.borderColor =
        'blue';
    }
  };

  const handleChangeHarmonizedCode = e => {
    setHarmonizedCode(e.target.value);
  };

  const handleChangeSortDescriptionForBasic = e => {
    setSortDescriptionForBasic(e.target.value);
  };

  const handleChangeHeight = e => {
    setHeight(e.target.value);
  };

  const handleChangeWidth = e => {
    setWidth(e.target.value);
  };

  const handleChangeWeight = e => {
    setWeight(e.target.value);
  };

  const handleChangeSupplier = e => {
    setSupplier(e.target.value);
    // console.log(e.target.value);
  };

  const handleChangeSupplierPO = e => {
    setSupplierPO(e.target.value);
  };

  const handleChangeWarrenty = e => {
    setWarrenty(e.target.value);
  };

  const handleChangeTexCode = e => {
    setTexCode(e.target.value);
  };

  const handleChangeAllowConsolidationForBasic = e => {
    setAllowConsolidationForBasic(e.target.value);
  };

  const handleChangeDefaultPackage = e => {
    setDefaultPackage(e.target.value);
  };

  const handleChangeMultipackQuantity = e => {
    setMultipackQuantity(e.target.value);
  };

  const handleChangeWarehouseLocation = e => {
    setWarehouseLocation(e.target.value);
  };

  const hanldeChangeReceivedInInventory = e => {
    setReceivedInInventory(e.target.value);
  };

  const handleChangeStoreTitleForBasic = e => {
    setStoreTitleForBasic(e.target.value);
  };

  // for copy the titke value
  const handleClickForCopyTitle = () => {
    setStoreTitleForBasic(title);
  };

  const handleChangeDisplayInStore = e => {
    setDisplayInStore(e.target.value);
  };

  const handleChangeStoreCategoryForBasic = e => {
    setStoreCategoryForBasic(e.target.value);
  };

  const handleChangeStoreDescriptionForBasic = e => {
    setStoreDescriptionForBasic(e.target.value);
  };

  const hanldeChangeStoreMetaDescription = e => {
    setStoreMetaDescription(e.target.value);
  };

  const handleChangeCondition = e => {
    setCondition(e.target.value);
  };

  const handleChangeCategory = e => {
    setCategory(e.target.value);
    // console.log("target", e.target.value);
  };

  const handleChangeRetailPrice = e => {
    setRetailPrice(e.target.value);
  };

  const handleChangeStartingPrice = e => {
    setStartingPrice(e.target.value);
  };

  const handleChangeReservePrice = e => {
    setReservePrice(e.target.value);
  };

  const handleChangeStorePrice = e => {
    setStorePrice(e.target.value);
  };

  const hanldeChangeSellerCost = e => {
    console.log('e.target.value', e.target.value);
    setSellerCost(e.target.value);
  };

  const handleChangeProductMargin = e => {
    setProductMargin(e.target.value);
  };

  const handleChangeMinimumPrice = e => {
    setMinimumPrice(e.target.value);
  };

  const hanldeChangeMaximumPrice = e => {
    setMaximumPrice(e.target.value);
  };

  const handleChangeEstimatedShippingCost = e => {
    setEstimatedShippingCost(e.target.value);
  };

  const [dyammicData, setDynamicData] = useState({});

  const handleChangeDistributionCenterPhenox = (e, value) => {
    let temp = { ...dyammicData };
    temp[value] = e.target.value;
    setDynamicData(temp);
  };

  const fetchAgeRenge = () => {
    fetchUrl(`${BASIC_PRODUCT_AGE_RENGE}`, getVal => {
      if (getVal.status) {
        setAllAgeRenge(getVal.response.data.results);
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  const fetchMaterialName = () => {
    fetchUrl(`${BASIC_PRODUCT_MATERIAL_LIST}`, getVal => {
      if (getVal.status) {
        setAllMaterailName(getVal.response.data.results);
        setMaterailValue('Fabric');
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  useEffect(() => {
    fetchAgeRenge();
    fetchMaterialName();
  }, []);

  const handleAgeRangeChange = e => {
    // console.log(e.target.value)
    setAgeRange(e.target.value);
  };

  const handleAmzRepricerAutoPriceChange = e => {
    setAmzRepricerAutoPrice(e.target.value);
  };

  const handleCareChange = e => {
    setCare(e.target.value);
  };

  const handeColorChange = e => {
    setColor(e.target.value);
  };

  const handleDistributionDesignationAMZC = e => {
    setDistributionDesignationAMZC(e.target.value);
  };

  const handleEbayCatogoryId = e => {
    setEbayCatogoryId(e.target.value);
  };

  const handleEUSizeChange = e => {
    setEUSize(e.target.value);
  };

  const handleFightClubPrice = e => {
    setFightClubPrice(e.target.value);
  };

  const handleFlightClubCost = e => {
    setFlightClubCost(e.target.value);
  };

  const handleFlightClubQuantityBuffered = e => {
    setFlightClubQuantityBuffered(e.target.value);
  };

  const handleFootwearSizeSizeAMZCASu = e => {
    setFootwearSizeSizeAMZCASu(e.target.value);
  };

  const handleFootwearSizeSizeSystemA = e => {
    setFootwearSizeSizeSystemA(e.target.value);
  };

  const hanldeFootwearSizeWidthAMZCAS = e => {
    setFootwearSizeWidthAMZCAS(e.target.value);
  };

  const handleGenderChange = e => {
    setGender(e.target.value);
  };

  const handleChangeForGoatSku = e => {
    setGoatSku(e.target.value);
  };

  const handleChnageForItemPackageQuantity = e => {
    setItemPackageQuantity(e.target.value);
  };

  const handleChangeOrigin = e => {
    setOrigin(e.target.value);
  };

  const handleChangePrimaryColor = e => {
    setPrimaryColor(e.target.value);
  };

  const handleChangeProductWeight = e => {
    setProductWeight(e.target.value);
  };

  const handleChangeReleaseYear = e => {
    setReleaseYear(e.target.value);
  };

  const handleChangeShopifyPosition = e => {
    setShopifyPosition(e.target.value);
  };

  const handleChangeShopifyProductType = e => {
    setShopifyProductType(e.target.value);
  };

  const handleChangeShopifyProductURL = e => {
    setShopifyProductURL(e.target.value);
  };

  const handleChangeSize = e => {
    setSize(e.target.value);
  };

  const handleChangeUkSize = e => {
    setUkSize(e.target.value);
  };

  const handleChangeWalmartBulletPoints = e => {
    setWalmartBulletPoints(e.target.value);
  };

  const handleChangeWalmartShoeCategory = e => {
    setWalmartShoeCategory(e.target.value);
  };

  const handleChangeMaterailValue = e => {
    setMaterailValue(e.target.value);
  };

  const handleOpen = value => {
    setImageName(value);
    setOpen(true);
  };

  const handleClose = () => {
    setImageName('');
    setOpen(false);
  };

  const handleChangeImageLink = e => {
    setImageLink(e.target.files[0]);
    // console.log("e", e.target.files[0])
  };

  const [sweetAlert, setSweetAlert] = useState('');

  // function to show/hide loading Overlay
  const loadingAction = action => {
    action ? setLoading(true) : setLoading(false);
  };

  const hideAlert = (mode, action) => {
    setSweetAlert('');
  };

  // function to show Message pops on success/error response
  const fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="BasicProductPge"
        action={stateOfAction}
        hideAlert={hideAlert}
        hideOverlay={loadingAction}
        response={response}
        status={status}
      />
    );
    setSweetAlert(message);
  };

  const [allImageUploadedData, setAllImageUploadedData] = useState(
    []
  );

  // console.log("imaheid", imageId)
  // hndle clear function
  const handleClearImage = (Image, imageName) => {
    setLoading(true);
    let url = allImageUploadedData.filter(
      item => item.url === Image
    );
    let urlId = url && url[0] && url[0].id;
    setSweetAlert('');
    apiDelete(
      `${DELETE_IMAGE_LINK}${urlId}/`,
      'delete',
      ({ status, response }) => {
        if (status) {
          if (imageId.length > 1) {
            let imagesID = imageId.filter(id => id != urlId);
            setImageId([...imagesID]);
            // console.log("url", urlId, imagesID)
          } else {
            setImageId([]);
          }
          setLoading(false);
          if (imageName === 'ITEMIMAGEURL1') {
            setITEMIMAGEURL1('');
          } else if (imageName === 'ITEMIMAGEURL2') {
            setITEMIMAGEURL2('');
          } else if (imageName === 'ITEMIMAGEURL3') {
            setITEMIMAGEURL3('');
          } else if (imageName === 'ITEMIMAGEURL4') {
            setITEMIMAGEURL4('');
          } else if (imageName === 'ITEMIMAGEURL5') {
            setITEMIMAGEURL5('');
          } else if (imageName === 'ITEMIMAGEURL6') {
            setITEMIMAGEURL6('');
          } else if (imageName === 'ITEMIMAGEURL7') {
            setITEMIMAGEURL7('');
          } else {
            setITEMIMAGEURL8('');
          }
          // console.log("delete respose", response)
          fetchMessage(true, response, 'delete');
        } else {
          setLoading(false);
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (checkFunction(response)) {
            fetchMessage('redirectTrue');
          } else {
            fetchMessage(false, response.response, 'delete');
          }
        }
      }
    );
  };

  const handleImageUpload = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('placements', [imageName]);
    formData.append('images', imageLink, imageLink.name);

    apiPost(BASIC_IMAGE_UPLOAD, formData, ({ status, response }) => {
      if (status) {
        setOpen(false);
        setLoading(false);
        setAllImageUploadedData([
          ...allImageUploadedData,
          response.data.images &&
            response.data.images[0] &&
            response.data.images[0]
        ]);
        let urlImage =
          response.data.images &&
          response.data.images[0] &&
          response.data.images[0].url;
        if (imageId.length > 0) {
          setImageId([
            ...imageId,
            response.data.images &&
              response.data.images[0] &&
              response.data.images[0].id
          ]);
        } else {
          setImageId([
            response.data.images &&
              response.data.images[0] &&
              response.data.images[0].id
          ]);
        }

        if (imageName === 'ITEMIMAGEURL1') {
          setITEMIMAGEURL1(urlImage);
        } else if (imageName === 'ITEMIMAGEURL2') {
          setITEMIMAGEURL2(urlImage);
        } else if (imageName === 'ITEMIMAGEURL3') {
          setITEMIMAGEURL3(urlImage);
        } else if (imageName === 'ITEMIMAGEURL4') {
          setITEMIMAGEURL4(urlImage);
        } else if (imageName === 'ITEMIMAGEURL5') {
          setITEMIMAGEURL5(urlImage);
        } else if (imageName === 'ITEMIMAGEURL6') {
          setITEMIMAGEURL6(urlImage);
        } else if (imageName === 'ITEMIMAGEURL7') {
          setITEMIMAGEURL7(urlImage);
        } else {
          setITEMIMAGEURL8(urlImage);
        }
      } else {
        setLoading(false);
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else errorRedirect(response.response);
      }
    });
  };

  // function to check the error due to 404/500
  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  const CreateBasicNewProduct = () => {
    // distribution Center
    let fData = [];
    for (let i = 0; i < destributionCenter.length; i++) {
      let temp = {};
      temp['name'] = destributionCenter[i]['name'];
      temp['qty'] = dyammicData[destributionCenter[i]['id']]
        ? Number(dyammicData[destributionCenter[i]['id']])
        : 0;
      fData.push(temp);
    }

    let data = {
      is_blocked: false,
      asin: asin,
      brand: brand,
      condition: condition,
      description: "",
      ean: "",
      harmonized_code: harmonizedCode,
      isbn: isbn,
      manufacturer: manufacturer,
      mpn: mpn,
      short_description: sortDescriptionForBasic,
      sku: sku,
      subtitle: subTitle,
      // 'tax_product_code',
      title: title,
      upc: "",
      warehouse_location: warehouseLocation,
      warranty: warrenty,
      height: height,
      // 'length': length,
      width: widths,
      weight: weight,
      // 'cost' : cost,
      supplier_name: supplier,
      supplier_code: '',
      supplier_po: supplierPO,
      classification: '',
      store_title: storeTitleForBasic,
      is_display_in_store: displayInStore === 'Yes' ? true : false,
      store_category: storeCategoryForBasic,
      store_description: storeDescriptionForBasic,
      total_quantity: 0,
      tax_code: texCode,
      allow_consolidation: allowConsolidationForBasic,
      default_package: defaultPackage,
      multipack_quantity: multipackQuantity,
      received_date_utc: receivedInInventory,
      store_meta_description: storeMetaDescription,
      usp: "",
      margin: productMargin,
      retail_price: retailPrice,
      starting_price: startingPrice,
      reserve_price: reservePrice,
      buy_it_now_price: retailPrice,
      store_price: storePrice,
      cost: sellerCost,
      second_chance_price: 0,
      min_price: minimumPrice,
      max_price: maximumPrice,
      estimated_shipping_cost: estimatedShippingCost,
      category: category,
      distribution_centers: fData,
      images: imageId
    };

    let attributes = {
      age_range: ageRange,
      amz_repricer_auto_price: amzRepricerAutoPrice,
      // 'brand_ca_suggestion' : brandCASuggestion,
      care: care,
      color: color,
      // 'color_ca_suggestion': colorCASuggestion,
      distribution_designation_amzc: distributionDesignationAMZC,
      // 'ean_AMZ_ac_suggestion': eanAMZCASuggestion,
      ebay_category_id: ebayCatogoryId,
      eu_size: euSize,
      fight_club_price: fightClubPrice,
      fight_club_cost: flightClubCost,
      flight_club_quantity_buffered: flightClubQuantityBuffered,
      footwear_size_amz_ca_su: footwearSizeSizeAMZCASu,
      footwear_size_size_systema: footwearSizeSizeSystemA,
      footwear_size_width_AMZ_CAS: footwearSizeWidthAMZCAS,
      gender: gender,
      goat_sku: goatSku,
      item_package_quantity: itemPackageQuantity,
      material: materailValue,
      origin: origin,
      primary_color: primaryColor,
      product_weight: productWeight,
      release_year: releaseYear,
      shopify_position: shopifyPosition,
      shopify_product_type: shopifyProductType,
      shopify_product_url: shopifyProductURL,
      size: size,
      // 'size_ca_suggestion': sizeCASuggestion,
      sleeve_length: "",
      uk_size: ukSize,
      urbn_description: "",
      walmart_bullet_points: walmartBulletPoints,
      walmart_shoe_category: walmartShoeCategory
    };

    nonActiveConfigurationfields.forEach(currentValue => {
      if (currentValue in data) delete data[currentValue];
      else delete attributes[currentValue];
    });

    data.attributes = attributes;

    loadingAction(true);
    setSweetAlert('');
    if (sku && title && mpn) {
      apiPost(
        BASIC_PRODUCT_CREATE_API,
        data,
        ({ status, response }) => {
          if (status) {
            loadingAction(false);
            setOpen(false);
            fetchMessage(true, response, 'basic product created');
            setSku('');
            setTitle('');
            setMpn('');
          } else {
            loadingAction(false);
            if (
              response.response &&
              response.response.status &&
              response.response.status === 401
            ) {
              handleError(response.response);
            } else if (checkFunction(response)) {
              fetchMessage('redirectTrue');
            } else {
              fetchMessage(
                false,
                response.response,
                'basic product created'
              );
            }
          }
        }
      );
    } else {
      loadingAction(false);
      if (sku.length === 0) {
        document.getElementById('skuForBasic').style.borderColor =
          '#D10000';
      }
      if (title.length === 0) {
        document.getElementById('titleForBasic').style.borderColor =
          '#D10000';
      }
      if (mpn.length === 0) {
        document.getElementById('mpnForBasic').style.borderColor =
          '#D10000';
      }
    }
  };

  const ageRengeOption =
    allAgeRenge &&
    allAgeRenge.map(a => <option value={a.name}>{a.name}</option>);

  const materialOption =
    allMaterailName &&
    allMaterailName.map(value => (
      <option value={value.name}>{value.name}</option>
    ));

  const allowConsolidations =
    allowConsolidation &&
    allowConsolidation.map(a => (
      <option value={a.name}>{a.name}</option>
    ));

  return (
    <div className="ProdutsAddPage">
      <Permission />
      <MenuList />
      <div className="heading">
        <h1 id="catalog-title">Create New SKU</h1>
        <div className="proBtns">
          <button
            type="button"
            onClick={() => CreateBasicNewProduct()}
          >
            Create
          </button>
          <Link to="/catalogs">
            <i aria-hidden="true" /> Cancel
          </Link>
        </div>
      </div>
      <div className="content-warpper">
        <div className="content-body BasicPdts">
          {/* <CreateBasicProductTab /> */}
          <div className="btnTabs">
            <button
              id="product"
              onClick={() => handleChangeForProduct('product')}
            >
              Product
            </button>
            <button
              id="Image"
              onClick={() => handleChangeForProduct('image')}
            >
              Image
            </button>
          </div>
          <div>
            {isSelctedTab ? (
              // <>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={10}>
                    <div className="infoBox">
                      <div id="basicInfo">
                        <h5>
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                          />{' '}
                          Basic Info
                        </h5>
                        {configuration.sku && (
                          <>
                            <label for="skuForBasic">
                              SKU
                              <span
                                style={{
                                  color: '#ff0000',
                                  fontSize: '15px'
                                }}
                              >
                                *
                              </span>
                              :
                            </label>
                            <input
                              required
                              type="text"
                              name="sku"
                              className="form-control"
                              id="skuForBasic"
                              placeholder="Enter Sku"
                              value={sku}
                              onChange={hanldeChangeSku}
                            />
                            <span className="infoLabel">
                              Example: VN000EWZBLK-8-5(sku) =
                              VN000EWZBLK(mpn)-8-5
                            </span>
                            <br />
                          </>
                        )}
                        {configuration.mpn && (
                          <>
                            <label for="mpnForBasic">
                              MPN
                              <span
                                style={{
                                  color: '#ff0000',
                                  fontSize: '15px'
                                }}
                              >
                                *
                              </span>
                              :
                            </label>
                            <input
                              required
                              type="text"
                              name="mpn"
                              className="form-control"
                              id="mpnForBasic"
                              value={mpn}
                              placeholder="Enter MPN"
                              onChange={handleChangeMpn}
                            />
                            <span className="infoLabel">
                              Example: VN000EWZBLK-8-5(sku) =
                              VN000EWZBLK(mpn)-8-5
                            </span>
                            <br />
                          </>
                        )}
                        {configuration.title && (
                          <>
                            <label for="titleForBasic">
                              Title
                              <span
                                style={{
                                  color: '#ff0000',
                                  fontSize: '15px'
                                }}
                              >
                                *
                              </span>
                              :
                            </label>
                            <input
                              required
                              type="text"
                              name="title"
                              className="form-control"
                              id="titleForBasic"
                              value={title}
                              placeholder="Enter Title"
                              onChange={handleChangeTitle}
                            />
                          </>
                        )}
                        {configuration.subtitle && (
                          <>
                            <label for="subtitleForBasic">
                              Subtitle:{' '}
                            </label>
                            <input
                              type="text"
                              name="subtitle"
                              className="form-control"
                              id="subtitleForBasic"
                              placeholder="Enter Subtitle"
                              value={subTitle}
                              onChange={handleChangeSubTitle}
                            />
                            <br />
                          </>
                        )}
                        {configuration.brand && (
                          <>
                            <label for="brandForBasic">
                              Brand:{' '}
                            </label>
                            <input
                              type="text"
                              name="brand"
                              className="form-control"
                              id="brandForBasic"
                              placeholder="Enter Brand"
                              value={brand}
                              onChange={hanldeChangeBrand}
                            />
                          </>
                        )}
                        <label for="categoryForBasic">
                          {' '}
                          Category:
                        </label>
                        <select
                          className="custom-select"
                          value={category}
                          onChange={e => handleChangeCategory(e)}
                        >
                          <option selected value="" />
                          <option value="Shoes">Shoes</option>
                          <option value="Clothing">Clothing</option>
                        </select>
                        {configuration.manufacturer && (
                          <>
                            <label for="manufacturerForBasic">
                              Manufacturer:{' '}
                            </label>
                            <input
                              type="text"
                              name="manufacturer"
                              className="form-control"
                              id="manufacturerForBasic"
                              placeholder=""
                              value={manufacturer}
                              disabled
                            />
                          </>
                        )}
                        {configuration.condition && (
                          <>
                            <label for="conditionForBasic">
                              {' '}
                              Condition:
                            </label>
                            <select
                              className="custom-select"
                              value={condition}
                              onChange={e =>
                                handleChangeCondition(e)
                              }
                            >
                              <option selected value="" />
                              <option value="New">New</option>
                              <option value="Used">Used</option>
                              <option value="Refurbished">
                                Refurbished
                              </option>
                              <option value="Reconditioned">
                                Reconditioned
                              </option>
                              <option value="Like New">
                                Like New
                              </option>
                            </select>
                          </>
                        )}
                        {configuration.asin && (
                          <>
                            <label for="asinForBasic">ASIN: </label>
                            <input
                              type="text"
                              name="asin"
                              className="form-control"
                              id="asinForBasic"
                              value={asin}
                              onChange={hanldeChangeAsin}
                            />
                          </>
                        )}
                        {configuration.isbn && (
                          <>
                            <label for="isbnorBasic">ISBN: </label>
                            <input
                              type="text"
                              name="isbn"
                              className="form-control "
                              id="isbnForBasic"
                              value={isbn}
                              onChange={hanldeChangeIsbn}
                            />
                          </>
                        )}
                        {configuration.harmonized_code && (
                          <>
                            <label for="harmonizedCodeForBasic">
                              Harmonized Code:
                            </label>
                            <input
                              type="text"
                              name="harmonizedCode"
                              className="form-control "
                              id="harmonizedCodeForBasic"
                              value={harmonizedCode}
                              onChange={handleChangeHarmonizedCode}
                            />
                          </>
                        )}
                        {configuration.short_description && (
                          <>
                            <label for="sortDescriptionForBasic">
                              Short Description:
                            </label>
                            <textarea
                              id="sortDescriptionForBasic"
                              className="form-control"
                              value={sortDescriptionForBasic}
                              name="sortDescriptionForBasic"
                              onChange={
                                handleChangeSortDescriptionForBasic
                              }
                            />
                          </>
                        )}
                        {configuration.height && (
                          <>
                            <label for="heightForBasic">
                              Height (inches):{' '}
                            </label>
                            <input
                              type="text"
                              name="height"
                              className="form-control "
                              id="heightForBasic"
                              value={height}
                              onChange={handleChangeHeight}
                            />
                          </>
                        )}
                        {configuration.width && (
                          <>
                            <label for="widthForBasic">
                              Width (inches):{' '}
                            </label>
                            <input
                              type="text"
                              name="widths"
                              className="form-control "
                              id="widthForBasic"
                              value={widths}
                              onChange={handleChangeWidth}
                            />
                          </>
                        )}
                        {configuration.weight && (
                          <>
                            <label for="weightForBasic">
                              Weight (lb):{' '}
                            </label>
                            <input
                              type="text"
                              name="weight"
                              className="form-control "
                              id="weightForBasic"
                              value={weight}
                              onChange={handleChangeWeight}
                            />
                          </>
                        )}
                        {configuration.supplier_name && (
                          <>
                            <label for="conditionForBasic">
                              Supplier:
                            </label>
                            <select
                              className="custom-select"
                              value={supplier}
                              onChange={e => handleChangeSupplier(e)}
                            >
                              <option selected value="">
                                Select Supplier
                              </option>
                              <option value=""> </option>
                              {/* <option value="1">One</option>
                                                          <option value="2">Two</option>
                                                          <option value="3">Three</option> */}
                            </select>
                            <br />
                          </>
                        )}
                        {configuration.supplier_po && (
                          <>
                            <label for="supplierPOForBasic">
                              Supplier PO:{' '}
                            </label>
                            <input
                              type="text"
                              name="supplierPO"
                              className="form-control "
                              id="supplierPOForBasic"
                              value={supplierPO}
                              onChange={handleChangeSupplierPO}
                            />
                          </>
                        )}
                        {configuration.warranty && (
                          <>
                            <label for="warrantyForBasic">
                              {' '}
                              Warranty:{' '}
                            </label>
                            <textarea
                              id="warrantyForBasic"
                              className="form-control"
                              name="warrenty"
                              value={warrenty}
                              onChange={handleChangeWarrenty}
                            />
                          </>
                        )}
                        {configuration.tax_code && (
                          <>
                            <label for="taxCodeForBasic">
                              Tax Code:{' '}
                            </label>
                            <input
                              type="text"
                              name="texCode"
                              className="form-control "
                              id="taxCodeForBasic"
                              value={texCode}
                              onChange={handleChangeTexCode}
                            />
                          </>
                        )}
                        {configuration.allow_consolidation && (
                          <>
                            <label for="allowConsolidationForBasic">
                              Allow Consolidation:
                            </label>
                            <select
                              className="custom-select"
                              value={allowConsolidationForBasic}
                              onChange={e =>
                                handleChangeAllowConsolidationForBasic(
                                  e
                                )
                              }
                            >
                              {allowConsolidations}
                            </select>
                            <br />
                          </>
                        )}
                        {configuration.default_package && (
                          <>
                            <label for="defaultPackageForBasic">
                              Default Package:
                            </label>
                            <select
                              className="custom-select"
                              value={defaultPackage}
                              onChange={e =>
                                handleChangeDefaultPackage(e)
                              }
                            >
                              <option selected> </option>
                              <option value="Use Items Attribute For Package Dimentions">
                                Use Items Attribute For Package
                                Dimentions
                              </option>
                            </select>
                            <br />
                          </>
                        )}
                        {configuration.multipack_quantity && (
                          <>
                            <label for="multipackQuantityForBasic">
                              Multipack Quantity:
                            </label>
                            <input
                              type="number"
                              step="0.01"
                              name="multipackQuantity"
                              className="form-control"
                              id="multipackQuantityForBasic"
                              value={multipackQuantity}
                              onChange={
                                handleChangeMultipackQuantity
                              }
                            />
                          </>
                        )}
                        {configuration.warehouse_location && (
                          <>
                            <label for="warehouseLocationForBasic">
                              Warehouse Location:
                            </label>
                            <input
                              type="text"
                              name="warehouseLocation"
                              className="form-control"
                              id="warehouseLocationForBasic"
                              value={warehouseLocation}
                              onChange={
                                handleChangeWarehouseLocation
                              }
                            />
                          </>
                        )}
                        {configuration.received_date_utc && (
                          <>
                            <label for="receivedInInventoryForBasic">
                              Received In Inventory:
                            </label>
                            <input
                              type="date"
                              name="receivedInInventory"
                              className="form-control"
                              id="receivedInInventoryForBasic"
                              value={receivedInInventory}
                              onChange={
                                hanldeChangeReceivedInInventory
                              }
                            />
                          </>
                        )}
                        {configuration.store_title && (
                          <>
                            <label for="storeTitleForBasic">
                              Store Title:{' '}
                            </label>
                            <input
                              type="text"
                              name="storeTitleForBasic"
                              className="form-control"
                              id="storeTitleForBasic"
                              value={storeTitleForBasic}
                              onChange={
                                handleChangeStoreTitleForBasic
                              }
                            />
                            <span
                              className="textCopy"
                              onClick={() =>
                                handleClickForCopyTitle()
                              }
                            >
                              Copy Product Title
                            </span>
                          </>
                        )}
                        {configuration.is_display_in_store && (
                          <>
                            <label for="displayInStoreForBasic">
                              Display In Store:
                            </label>
                            <select
                              className="custom-select"
                              value={displayInStore}
                              onChange={e =>
                                handleChangeDisplayInStore(e)
                              }
                            >
                              <option selected />
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </select>
                            <br />
                          </>
                        )}
                        {configuration.store_category && (
                          <>
                            <label for="storeCategoryForBasic">
                              Store Category:
                            </label>
                            <input
                              type="text"
                              name="storeCategoryForBasic"
                              className="form-control "
                              id="storeCategoryForBasic"
                              value={storeCategoryForBasic}
                              onChange={
                                handleChangeStoreCategoryForBasic
                              }
                            />
                          </>
                        )}
                        {configuration.store_description && (
                          <>
                            <label for="storeDescriptionForBasic">
                              Store Description:
                            </label>
                            <textarea
                              id="storeDescriptionForBasic"
                              className="form-control"
                              name="storeDescriptionForBasic"
                              value={storeDescriptionForBasic}
                              onChange={
                                handleChangeStoreDescriptionForBasic
                              }
                            />
                          </>
                        )}
                        {configuration.store_meta_description && (
                          <>
                            <label for="storeMetaDescriptionForBasic">
                              Store Meta Description:
                            </label>
                            <textarea
                              id="storeMetaDescriptionForBasic"
                              className="form-control"
                              name="storeMetaDescription"
                              value={storeMetaDescription}
                              onChange={
                                hanldeChangeStoreMetaDescription
                              }
                            />
                          </>
                        )}
                      </div>
                      <div id="pricing">
                        <div>
                          <h5>
                            <i
                              className="fa fa-tags"
                              aria-hidden="true"
                            />{' '}
                            Pricing
                          </h5>
                          {configuration.retail_price && (
                            <div className="input-group ">
                              <label for="retailPrice">
                                Retail Price:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="retailPrice"
                                id="retailPrice"
                                value={retailPrice}
                                onChange={handleChangeRetailPrice}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.buy_it_now_price && (
                            <div className="input-group ">
                              <label for="buyItNowPrice">
                                Buy-It-Now Price:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="buyItNowPrice"
                                id="buyItNowPrice"
                                value={retailPrice}
                                disabled
                              />
                            </div>
                          )}
                          {configuration.cost && (
                            <div className="input-group ">
                              <label for="Cost">Cost:</label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="cost"
                                id="cost"
                                value={sellerCost}
                                onChange={hanldeChangeSellerCost}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.starting_price && (
                            <div className="input-group ">
                              <label for="startingPrice">
                                Starting Price:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="startingPrice"
                                id="startingPrice"
                                value={startingPrice}
                                onChange={handleChangeStartingPrice}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.reserve_price && (
                            <div className="input-group ">
                              <label for="reservePrice">
                                Reserve Price:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="reservePrice"
                                id="reservePrice"
                                value={reservePrice}
                                onChange={handleChangeReservePrice}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.store_price && (
                            <div className="input-group ">
                              <label for="storePrice">
                                Store Price:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="storePrice"
                                id="storePrice"
                                value={storePrice}
                                onChange={handleChangeStorePrice}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.margin && (
                            <div className="input-group ">
                              <label for="productMargin">
                                Product Margin:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="productMargin"
                                id="productMargin"
                                value={productMargin}
                                onChange={handleChangeProductMargin}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.min_price && (
                            <div className="input-group ">
                              <label for="minimumPrice">
                                Minimum Price:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="minimumPrice"
                                id="minimumPrice"
                                value={minimumPrice}
                                onChange={handleChangeMinimumPrice}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.max_price && (
                            <div className="input-group ">
                              <label for="maximumPrice">
                                Maximum Price:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="maximumPrice"
                                id="maximumPrice"
                                value={maximumPrice}
                                onChange={hanldeChangeMaximumPrice}
                              />
                              <br />
                            </div>
                          )}
                          {configuration.estimated_shipping_cost && (
                            <div className="input-group ">
                              <label for="estimatedShippingCost">
                                Estimated Shipping Cost:
                              </label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  $
                                </span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="estimatedShippingCost"
                                id="estimatedShippingCost"
                                value={estimatedShippingCost}
                                onChange={
                                  handleChangeEstimatedShippingCost
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div id="distribution_center">
                        <h5>
                          <i
                            className="fa fa-warehouse"
                            aria-hidden="true"
                          />{' '}
                          {destributionCenter.length === 0 &&
                            'No Available '}
                          Distribution Centers
                        </h5>
                        <div>
                          {destributionCenter &&
                          destributionCenter.length > 0 ? (
                            <table>
                              <tr>
                                {/* <th></th> */}
                                <th>Distribution Center</th>
                                <th>Code</th>
                                <th>Type</th>
                                <th>Quantity</th>
                              </tr>

                              {destributionCenter
                                .filter(
                                  center => center.name !== 'Croydon'
                                )
                                .map(center => {
                                  return (
                                    <tr>
                                      <td key={center.id}>
                                        {center.name}
                                      </td>
                                      <td key={center.id}>
                                        {center.code}
                                      </td>
                                      <td key={center.id}>
                                        {center.type}
                                      </td>
                                      <td key={center.id}>
                                        <input
                                          type="number"
                                          step="0.01"
                                          className="form-control w-50"
                                          name={center.id}
                                          onChange={e =>
                                            handleChangeDistributionCenterPhenox(
                                              e,
                                              center.id
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </table>
                          ) : null}
                        </div>
                      </div>
                      <div id="attributes">
                        <h5>
                          <i
                            className="fa fa-server"
                            aria-hidden="true"
                          />{' '}
                          Attributes
                        </h5>
                        {configuration.age_range && (
                          <div className="input-group ">
                            <label for="ageRange">Age Range:</label>
                            <select
                              className="custom-select"
                              value={ageRange}
                              onChange={e => handleAgeRangeChange(e)}
                            >
                              {ageRengeOption}
                            </select>
                          </div>
                        )}
                        {configuration.amz_repricer_auto_price && (
                          <div className="input-group ">
                            <label for="amzRepricerAutoPrice">
                              AmzRepricerAutoPrice:
                            </label>
                            <textarea
                              id="amzRepricerAutoPrice"
                              name="amzRepricerAutoPrice"
                              value={amzRepricerAutoPrice}
                              className="form-control"
                              onChange={
                                handleAmzRepricerAutoPriceChange
                              }
                            >
                              AmzRepricerAutoPrice
                            </textarea>
                          </div>
                        )}
                        {/* <div className="input-group ">
                                                        <label for="BrandCASuggestion"> BrandCASuggestion: </label>
                                                        <textarea
                                                            id="BrandCASuggestion"
                                                            name="brandCASuggestion"
                                                            value={brandCASuggestion}
                                                            onChange={handleBrandCASuggestion}
                                                            className="form-control"
                                                        >
                                                        </textarea>
                                                    </div> */}
                        {configuration.care && (
                          <div className="input-group ">
                            <label for="Care"> Care: </label>
                            <textarea
                              id="Care"
                              className="form-control"
                              value={care}
                              name="care"
                              onChange={handleCareChange}
                            />
                          </div>
                        )}
                        {configuration.color && (
                          <div className="input-group ">
                            <label for="Color"> Color: </label>
                            <textarea
                              id="Color"
                              className="form-control"
                              name="color"
                              value={color}
                              onChange={handeColorChange}
                            />
                          </div>
                        )}
                        {/* <div className="input-group ">
                                                        <label for="ColorCASuggestion"> ColorCASuggestion: </label>
                                                        <textarea
                                                            id="ColorCASuggestion"
                                                            className="form-control"
                                                            name="colorCASuggestion"
                                                            value={colorCASuggestion}
                                                            onChange={handleColorCASuggestion}
                                                        >
                                                        </textarea>
                                                    </div> */}
                        {configuration.distribution_designation_amzc && (
                          <div className="input-group ">
                            <label for="DistributionDesignationAMZC">
                              DistributionDesignationAMZC:
                            </label>
                            <textarea
                              id="DistributionDesignationAMZC"
                              className="form-control"
                              name="distributionDesignationAMZC"
                              value={distributionDesignationAMZC}
                              onChange={
                                handleDistributionDesignationAMZC
                              }
                            />
                          </div>
                        )}
                        {/* <div className="input-group ">
                                                        <label for="EanAMZCASuggestion"> EanAMZCASuggestion: </label>
                                                        <textarea
                                                            id="eanAMZCASuggestion"
                                                            className="form-control"
                                                            name="eanAMZCASuggestion"
                                                            value={eanAMZCASuggestion}
                                                            onChange={handleEanAMZCASuggestion}
                                                        >
                                                        </textarea>
                                                    </div> */}
                        {configuration.ebay_category_id && (
                          <div className="input-group ">
                            <label for="EBAY_CATEGORY_ID">
                              EBAY_CATEGORY_ID:
                            </label>
                            <textarea
                              id="ebayCatogoryId"
                              className="form-control"
                              name="ebayCatogoryId"
                              value={ebayCatogoryId}
                              onChange={handleEbayCatogoryId}
                            />
                          </div>
                        )}
                        {configuration.eu_size && (
                          <div className="input-group ">
                            <label for="EUSize"> EU Size: </label>
                            <textarea
                              id="EUSize"
                              className="form-control"
                              name="euSize"
                              value={euSize}
                              onChange={handleEUSizeChange}
                            />
                          </div>
                        )}
                        {configuration.fight_club_price && (
                          <div className="input-group ">
                            <label for="fightClubPrice">
                              Fight Club Price:
                            </label>
                            <textarea
                              id="fightClubPrice"
                              className="form-control"
                              name="fightClubPrice"
                              value={fightClubPrice}
                              onChange={handleFightClubPrice}
                            />
                          </div>
                        )}
                        {configuration.fight_club_cost && (
                          <div className="input-group ">
                            <label for="flightClubCost">
                              Flight Club Cost:
                            </label>
                            <textarea
                              id="flightClubCost"
                              className="form-control"
                              name="flightClubCost"
                              value={flightClubCost}
                              onChange={handleFlightClubCost}
                            />
                          </div>
                        )}
                        {configuration.flight_club_quantity_buffered && (
                          <div className="input-group ">
                            <label for="flightClubQuantityBuffered">
                              Flight Club Quantity Buffered:
                            </label>
                            <textarea
                              id="flightClubQuantityBuffered"
                              className="form-control"
                              name="flightClubQuantityBuffered"
                              value={flightClubQuantityBuffered}
                              onChange={
                                handleFlightClubQuantityBuffered
                              }
                            />
                          </div>
                        )}
                        {configuration.footwear_size_amz_ca_su && (
                          <div className="input-group ">
                            <label for="FootwearSizeSizeAMZCASu">
                              FootwearSizeSizeAMZCASu:
                            </label>
                            <textarea
                              id="FootwearSizeSizeAMZCASu"
                              className="form-control"
                              name="footwearSizeSizeAMZCASu"
                              value={footwearSizeSizeAMZCASu}
                              onChange={
                                handleFootwearSizeSizeAMZCASu
                              }
                            />
                          </div>
                        )}
                        {configuration.footwear_size_size_systema && (
                          <div className="input-group ">
                            <label for="FootwearSizeSizeSystemA">
                              FootwearSizeSizeSystemA:
                            </label>
                            <textarea
                              id="FootwearSizeSizeSystemA"
                              className="form-control"
                              name="footwearSizeSizeSystemA"
                              value={footwearSizeSizeSystemA}
                              onChange={
                                handleFootwearSizeSizeSystemA
                              }
                            />
                          </div>
                        )}
                        {configuration.footwear_size_width_AMZ_CAS && (
                          <div className="input-group ">
                            <label for="FootwearSizeWidthAMZCAS">
                              FootwearSizeWidthAMZCAS:
                            </label>
                            <textarea
                              id="FootwearSizeWidthAMZCAS"
                              className="form-control"
                              name="footwearSizeWidthAMZCAS"
                              value={footwearSizeWidthAMZCAS}
                              onChange={
                                hanldeFootwearSizeWidthAMZCAS
                              }
                            />
                          </div>
                        )}
                        {configuration.gender && (
                          <div className="input-group ">
                            <label for="gender">Gender: </label>
                            <textarea
                              id="gender"
                              className="form-control"
                              name="gender"
                              value={gender}
                              onChange={handleGenderChange}
                            />
                          </div>
                        )}
                        {configuration.goat_sku && (
                          <div className="input-group ">
                            <label for="goatSKU">GOAT SKU: </label>
                            <textarea
                              id="goatSKU"
                              className="form-control"
                              name="goatSku"
                              value={goatSku}
                              onChange={handleChangeForGoatSku}
                            />
                          </div>
                        )}
                        {configuration.item_package_quantity && (
                          <div className="input-group ">
                            <label for="itemPackageQuantity">
                              Item Package Quantity:
                            </label>
                            <textarea
                              id="itemPackageQuantity"
                              className="form-control"
                              name="itemPackageQuantity"
                              value={itemPackageQuantity}
                              onChange={
                                handleChnageForItemPackageQuantity
                              }
                            />
                          </div>
                        )}
                        {configuration.material && (
                          <div className="input-group ">
                            <label for="material">Material:</label>
                            <select
                              className="custom-select"
                              value={materailValue}
                              onChange={e =>
                                handleChangeMaterailValue(e)
                              }
                            >
                              {materialOption}
                            </select>
                          </div>
                        )}
                        {configuration.origin && (
                          <div className="input-group ">
                            <label for="origin">Origin: </label>
                            <textarea
                              id="origin"
                              className="form-control"
                              name="origin"
                              value={origin}
                              onChange={handleChangeOrigin}
                            />
                          </div>
                        )}
                        {configuration.primary_color && (
                          <div className="input-group ">
                            <label for="primaryColor">
                              Primary Color:{' '}
                            </label>
                            <textarea
                              id="primaryColor"
                              className="form-control"
                              name="primaryColor"
                              value={primaryColor}
                              onChange={handleChangePrimaryColor}
                            />
                          </div>
                        )}
                        {configuration.product_weight && (
                          <div className="input-group ">
                            <label for="productWeight">
                              Product Weight:{' '}
                            </label>
                            <textarea
                              id="productWeight"
                              className="form-control"
                              name="productWeight"
                              value={productWeight}
                              onChange={handleChangeProductWeight}
                            />
                          </div>
                        )}
                        {configuration.release_year && (
                          <div className="input-group ">
                            <label for="releaseYear">
                              Release Year:{' '}
                            </label>
                            <textarea
                              id="releaseYear"
                              className="form-control"
                              name="releaseYear"
                              value={releaseYear}
                              onChange={handleChangeReleaseYear}
                            />
                          </div>
                        )}
                        {configuration.shopify_position && (
                          <div className="input-group ">
                            <label for="shopifyPosition">
                              Shopify Position:
                            </label>
                            <textarea
                              id="shopifyPosition"
                              className="form-control"
                              name="shopifyPosition"
                              value={shopifyPosition}
                              onChange={handleChangeShopifyPosition}
                            />
                          </div>
                        )}
                        {configuration.shopify_product_type && (
                          <div className="input-group ">
                            <label for="shopifyProductType">
                              Shopify Product Type:
                            </label>
                            <textarea
                              id="shopifyProductType"
                              className="form-control"
                              name="shopifyProductType"
                              value={shopifyProductType}
                              onChange={
                                handleChangeShopifyProductType
                              }
                            />
                          </div>
                        )}
                        {configuration.shopify_product_url && (
                          <div className="input-group ">
                            <label for="shopifyProductURL">
                              ShopifyProductURL:
                            </label>
                            <textarea
                              id="shopifyProductURL"
                              className="form-control"
                              name="shopifyProductURL"
                              value={shopifyProductURL}
                              onChange={
                                handleChangeShopifyProductURL
                              }
                            />
                          </div>
                        )}
                        {configuration.size && (
                          <div className="input-group ">
                            <label for="size">Size: </label>
                            <textarea
                              id="size"
                              className="form-control"
                              name="size"
                              value={size}
                              onChange={handleChangeSize}
                            />
                          </div>
                        )}
                        {/* <div className="input-group ">
                                                        <label for="sizeCASuggestion">SizeCASuggestion: </label>
                                                        <textarea
                                                            id="sizeCASuggestion"
                                                            className="form-control"
                                                            name="sizeCASuggestion"
                                                            value={sizeCASuggestion}
                                                            onChange={handleChangeSizeCASuggestion}
                                                        >
                                                        </textarea>
                                                    </div> */}
                        {configuration.uk_size && (
                          <div className="input-group ">
                            <label for="ukSize">UK Size: </label>
                            <textarea
                              id="ukSize"
                              className="form-control"
                              name="ukSize"
                              value={ukSize}
                              onChange={handleChangeUkSize}
                            />
                          </div>
                        )}
                        {configuration.walmart_bullet_points && (
                          <div className="input-group ">
                            <label for="walmartBulletPoints">
                              Walmart Bullet Points:
                            </label>
                            <textarea
                              id="walmartBulletPoints"
                              className="form-control"
                              name="walmartBulletPoints"
                              value={walmartBulletPoints}
                              onChange={
                                handleChangeWalmartBulletPoints
                              }
                            />
                          </div>
                        )}
                        {configuration.walmart_shoe_category && (
                          <div className="input-group ">
                            <label for="walmartShoeCategory">
                              Walmart Shoe Category:
                            </label>
                            <textarea
                              id="walmartShoeCategory"
                              className="form-control"
                              name="walmartShoeCategory"
                              value={walmartShoeCategory}
                              onChange={
                                handleChangeWalmartShoeCategory
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <div className="RTMenu">
                      <button className="tablinks" id="defaultOpen">
                        <i className="fas fa-angle-left" />
                        <a href="#basicInfo"> Basic Info </a>
                      </button>
                      <button className="tablinks">
                        <i className="fas fa-angle-left" />
                        <a href="#pricing"> Pricing</a>
                      </button>
                      <button className="tablinks">
                        <i className="fas fa-angle-left" />
                        <a href="#distribution_center">
                          {' '}
                          Distribution Centers
                        </a>
                      </button>
                      <button className="tablinks">
                        <i className="fas fa-angle-left" />
                        <a href="#attributes"> Attributes</a>
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className="infoBox">
                <h5>
                  <i className="fa fa-images" /> Images
                </h5>
                <div className="imageUpload">
                  <Grid container spacing={2}>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL1</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL1}
                            alt="ITEMIMAGEURL1"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL1"
                            value={ITEMIMAGEURL1}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL1')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL1,
                                'ITEMIMAGEURL1'
                              )
                            }
                          >
                            <i className="fa fa-times-circle" />{' '}
                            Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL2</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL2}
                            alt="ITEMIMAGEURL2"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL2"
                            value={ITEMIMAGEURL2}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL2')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL2,
                                'ITEMIMAGEURL2'
                              )
                            }
                          >
                            <i class="fa fa-times-circle" /> Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL3</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL3}
                            alt="ITEMIMAGEURL3"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL3"
                            value={ITEMIMAGEURL3}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL3')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL3,
                                'ITEMIMAGEURL3'
                              )
                            }
                          >
                            <i class="fa fa-times-circle" /> Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL4</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL4}
                            alt="ITEMIMAGEURL4"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL4"
                            value={ITEMIMAGEURL4}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL4')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL4,
                                'ITEMIMAGEURL4'
                              )
                            }
                          >
                            <i class="fa fa-times-circle" /> Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL5</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL5}
                            alt="ITEMIMAGEURL5"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL5"
                            value={ITEMIMAGEURL5}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL5')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL5,
                                'ITEMIMAGEURL5'
                              )
                            }
                          >
                            <i class="fa fa-times-circle" /> Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL7</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL6}
                            alt="ITEMIMAGEURL6"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL6"
                            value={ITEMIMAGEURL6}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL6')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL6,
                                'ITEMIMAGEURL6'
                              )
                            }
                          >
                            <i class="fa fa-times-circle" /> Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL7</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL7}
                            alt="ITEMIMAGEURL7"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL7"
                            value={ITEMIMAGEURL7}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL7')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL7,
                                'ITEMIMAGEURL7'
                              )
                            }
                          >
                            <i class="fa fa-times-circle" /> Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="ImagUploadBox">
                      <h6>ITEMIMAGEURL8</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <img
                            src={ITEMIMAGEURL8}
                            alt="ITEMIMAGEURL8"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <textarea
                            className="form-control"
                            name="ITEMIMAGEURL8"
                            value={ITEMIMAGEURL8}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleOpen('ITEMIMAGEURL8')
                            }
                          >
                            <i class="fa fa-upload" /> Upload
                          </button>
                          <button
                            type="button"
                            style={{ marginLeft: '10px' }}
                            onClick={() =>
                              handleClearImage(
                                ITEMIMAGEURL8,
                                'ITEMIMAGEURL8'
                              )
                            }
                          >
                            <i className="fa fa-times-circle" />{' '}
                            Clear
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className="imageUploadPop"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <h5 id="transition-modal-title">
                        Import New Image For {imageName}
                      </h5>
                      <span
                        className="close"
                        onClick={() => handleClose()}
                      >
                        <i className="fa fa-times" />
                      </span>
                      <input
                        type="file"
                        id="myFile"
                        name="imageLink"
                        onChange={handleChangeImageLink}
                      />
                      <button
                        type="button"
                        onClick={() => handleImageUpload()}
                      >
                        Upload
                      </button>
                      <button
                        type="button"
                        onClick={() => handleClose()}
                      >
                        Close
                      </button>
                    </div>
                  </Fade>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
      <LoadingOverlay showOverlay={loading} />
      {sweetAlert}
    </div>
  );
};

export default CreateBasicProduct;
