import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop } from '@material-ui/core/';
import { AgGridReact } from "ag-grid-react";
import SweetAlert from "react-bootstrap-sweetalert";

import { PURCHASE_LIST_API } from "../configurations/configApi";
import {apiDelete, apiEdit, fetchUrl} from "../apiActions/action";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eee',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth : "1200px",
        position : "relative"
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    label: {
        textAlign: 'right',
        width: '70px',
        marginTop: '.5rem'
    }
}));


const ViewPurchases = ({ productIds, handleClose }) => {
    const classes = useStyles();

    const [gridApi, setGridApi] = useState();
    const [purchases, setPurchases] = useState([]);
    const [sweetAlert, setSweetAlert] = useState();

    useEffect(() => {
        if (productIds.length) {
            loadPurchases(productIds[0]);
        }

    }, [productIds]);

    const loadPurchases = (productId) => {
        fetchUrl(`${PURCHASE_LIST_API}?product_id=${productId}`, ({ status, response}) => {
            setPurchases(response.data);
        });
    };

    const defaultColDef = {
        flex: 1,
        editable: true,
        sortable: true
    };

    const columnDefs = [
        {
            headerName: "Cost",
            field: "cost",
        },
        {
            headerName: "Quantity",
            field: "quantity",
        },
        {
            headerName: "DateTime",
            field: "created_on",
            editable: false,
            valueFormatter: (params) => new Date(params.value).toLocaleString()
        },
        {
            headerName: "",
            field: "action",
            cellRendererFramework: params => (
                <div
                    className="text-right"
                    onClick={() => showDeleteModal(params.data, params.api)}>
                    <i
                        className="fa fa-trash"
                        aria-hidden="true"
                    />
                </div>
            ) //  To add  icon or link  in cell etc
        }
    ];

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onCellValueChanged = (event) => {
        const data = { [event.column.colId]: event.newValue };
        gridApi.showLoadingOverlay(); // to show loading
        // when cell event is having no value initially
        if (typeof event.newValue !== 'undefined') {
            apiEdit(
                `${PURCHASE_LIST_API}${event.data.id}/`,
                data,
                'patch',
                ({ status, response }) => {
                    if (status) {
                        gridApi.hideOverlay(); // to hide loading
                        setSweetAlert(<SweetAlert
                            success
                            onConfirm={() => setSweetAlert()}
                            timeout={1000}
                        >Success</SweetAlert>);
                    } else {
                        gridApi.hideOverlay(); // to hide input tag for fetching label
                        gridApi.forEachNode(function(rowNode) {
                            if (event.data.id === rowNode.data.id) {
                                const updated = JSON.parse(
                                    JSON.stringify(rowNode.data)
                                );
                                updated[event.column.colId] = event.oldValue;
                                rowNode.setData(updated);
                            }
                        });
                        setSweetAlert(<SweetAlert
                            error
                            onConfirm={() => setSweetAlert()}
                            timeout={1000}
                        >Failed</SweetAlert>);
                    }
                }
            );
        } else {
            gridApi.hideOverlay(); // to hide loading
        }
    };


    const showDeleteModal = (data, api) => {
        setSweetAlert(<SweetAlert
            title="Delete Purchase"
            onConfirm={() => deletePurchase(data, api)}
            onCancel={() => setSweetAlert()}
            confirmBtnText="OK"
            cancelBtnText="Cancel"
            showCancel
        >Do you want to delete this purchase?</SweetAlert>)
    }

    const deletePurchase = (data, api) => {
        apiDelete(
            `${PURCHASE_LIST_API}${data.id}/`,
            'delete',
            ({ status }) => {
                if (status) {
                    setSweetAlert(<SweetAlert
                        success
                        onConfirm={() => setSweetAlert()}
                        timeout={1000}
                    >Deleted</SweetAlert>);
                    api.applyTransaction({ remove: [data]});
                } else {
                    setSweetAlert(<SweetAlert
                        error
                        onConfirm={() => setSweetAlert()}
                        timeout={1000}
                    >Failed</SweetAlert>);
                }
            }
        )
    }
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <div
                        id="myGrid"
                        style={{
                            height: '500px',
                            width: '800px',
                        }}
                        className="ag-theme-alpine"
                    >
                        <AgGridReact
                            onGridReady={onGridReady}
                            rowData={purchases}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            onCellValueChanged={onCellValueChanged}
                        />
                    </div>
                </div>
            </Modal>
            {sweetAlert}
        </>
)
}

export default ViewPurchases;
