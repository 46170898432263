import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import {
  ORDER_CREATE_API_URL,
  ACTIVE_PURCHASE_COST_API,
  GENERATE_ORDER_ID
} from '../configurations/configApi';
import { apiPost, fetchUrl } from '../apiActions/action';
import {
  Modal,
  Grid,
  Backdrop,
  Typography
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { FormItem } from '../components/FormFields';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #eee',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '800px',
    maxHeight: '800px',
    overflowY: 'scroll',
    position: 'relative'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const mainForm = {
  key: '',
  title: 'Basic Info',
  items: [
    {
      name: 'order_id',
      label: 'Order ID',
      disabled: true,
      rules: {
        required: 'Order ID is required'
      }
    },
    {
      name: 'order_time',
      label: 'Order Time',
      type: 'datetime-local',
      min: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      rules: {
        required: 'Order Time is required',
        validate: value => {
          return (
            new Date() < new Date(value) ||
            'Order Time cannot be in the past'
          );
        }
      }
    },
    {
      name: 'order_number',
      label: 'Order Number'
    },
    {
      name: 'order_status',
      label: 'Order Status',
      component: 'select',
      items: [
        {
          label: 'Open',
          value: 'Open'
        },
        {
          label: 'Acknowledged',
          value: 'Acknowledged'
        },
        {
          label: 'Canceled',
          value: 'Canceled'
        },
        {
          label: 'Complete',
          value: 'Complete'
        },
        {
          label: 'Shipped',
          value: 'Shipped'
        }
      ],
      defaultValue: 'Open'
    },
    {
      name: 'cost',
      label: 'Cost',
      type: 'number',
      disabled: true,
      rules: {
        required: 'Cost is required'
      }
    },
    {
      name: 'qty',
      label: 'Quantity',
      type: 'number',
      rules: {
        required: 'Quantity is required'
      }
    },
    {
      name: 'amount',
      label: 'Amount',
      type: 'number',
      rules: {
        required: 'Amount is required'
      }
    },
    {
      name: 'discount',
      label: 'Discount',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'tax',
      label: 'Tax',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'shipping_fee',
      label: 'Shipping Fee',
      type: 'number',
      defaultValue: 0
    }
  ]
};

const shippingForm = {
  key: 'ship_to_info',
  title: 'Shipping',
  items: [
    {
      name: 'firstName',
      label: 'First Name',
      rules: {
        required: 'First Name is required'
      }
    },
    {
      name: 'lastName',
      label: 'Last Name',
      rules: {
        required: 'Last Name is required'
      }
    },
    {
      name: 'companyName',
      label: 'Company Name'
    },
    {
      name: 'city',
      label: 'City'
    },
    {
      name: 'addressLine1',
      label: 'Address'
    },
    {
      name: 'emailAddress',
      label: 'Email'
    },
    {
      name: 'daytimePhone',
      label: 'Day Time Phone'
    },
    {
      name: 'eveningPhone',
      label: 'Evening Time Phone'
    }
  ]
};

const billingForm = {
  key: 'ship_to_bill_info',
  title: 'Billing',
  items: [
    {
      name: 'firstName',
      label: 'First Name',
      rules: {
        required: 'First Name is required'
      }
    },
    {
      name: 'lastName',
      label: 'Last Name',
      rules: {
        required: 'Last Name is required'
      }
    },
    {
      name: 'companyName',
      label: 'Company Name'
    },
    {
      name: 'city',
      label: 'City'
    },
    {
      name: 'addressLine1',
      label: 'Address'
    },
    {
      name: 'emailAddress',
      label: 'Email'
    },
    {
      name: 'daytimePhone',
      label: 'Day Time Phone'
    },
    {
      name: 'eveningPhone',
      label: 'Evening Time Phone'
    }
  ]
};

const waybillForm = {
  key: 'waybill',
  title: 'WayBills',
  items: [
    {
      name: 'trackingNo',
      label: 'Tracking Number'
    },
    {
      name: 'shipLabelValue',
      label: 'Ship label value'
    }
  ]
};

const CreateInvoice = ({ productIds, handleClose }) => {
  const [isSameBillingAddress, setIsSameBillingAddress] = useState(
    true
  );
  const classes = useStyles();
  const { control, handleSubmit, setValue, errors } = useForm();

  useEffect(() => {
    if (productIds.length) {
      fetchUrl(
        `${ACTIVE_PURCHASE_COST_API}${productIds[0]}/`,
        ({ status, response }) => {
          if (status) {
            setValue('cost', response.data);
          }
        }
      );
    }
  }, [productIds]);

  useEffect(() => {
    if (productIds[0] && productIds.length === 1) {
        fetchOrderId();
    }
  }, [productIds[0]]);

  const fetchOrderId = () => {
    fetchUrl(`${GENERATE_ORDER_ID}`, getVal => {
      if (getVal.status) {
        setValue('order_id', getVal.response.data);
      }
    });
  };

  const renderFormItem = form => {
    const { key, title, items } = form;
    return (
      <div>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={2}>
          {items.map(
            ({ component, type, name, label, rules, ...rest }) => (
              <Grid item xs={4} key={key ? `${key}.${name}` : name}>
                <FormItem
                  component={component || 'input'}
                  type={type || 'text'}
                  name={key ? `${key}.${name}` : name}
                  label={rules?.required ? `${label}*` : label}
                  control={control}
                  errorText={
                    key
                      ? errors[key]?.[name]?.message
                      : errors[name]?.message
                  }
                  rules={rules}
                  {...rest}
                />
              </Grid>
            )
          )}
        </Grid>
      </div>
    );
  };

  const onSubmit = formData => {
    const data = {
      ...formData,
      product_id: productIds[0],
      order_time: new Date(formData.order_time).toISOString()
    };
    if (isSameBillingAddress) {
      data.ship_to_bill_info = Object.assign({}, data.ship_to_info);
    }
    apiPost(ORDER_CREATE_API_URL, data, ({ status }) => {
      handleClose(status);
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.paper}
        >
          {renderFormItem(mainForm)}
          {renderFormItem(shippingForm)}
          <label>
            <input
              name="isSameBillingAddress"
              type="checkbox"
              placeholder="aaa"
              checked={isSameBillingAddress}
              className="mr-2"
              onChange={e =>
                setIsSameBillingAddress(e.target.checked)
              }
            />
            Billing Address Matches Shipping
          </label>
          {!isSameBillingAddress && renderFormItem(billingForm)}
          {renderFormItem(waybillForm)}

          <Grid container justify="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={() => handleClose()}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};

export default CreateInvoice;
